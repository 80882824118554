import { Flex } from "components/LEGACY/styled-components/Flex"
import PatternPageContainer from "components/utils/PatternPageContainer"
import gastromiaLogo from "images/gastromia_logo.png"
import Image from "next/image"
import styles from "./NotFound.module.scss"

const NotFound = () => {
  return (
    <PatternPageContainer>
      <Flex column justifyCenter alignCenter height="100%">
        <div style={{ position: "relative", padding: "1rem" }}>
          <Image layout="fixed" src={gastromiaLogo} alt="GASTROMIA logo" />
        </div>
        <h1 className={styles.heading}>Seite nicht gefunden</h1>
        <p className={styles.text}>Leider ist da etwas schief gelaufen.</p>
      </Flex>
    </PatternPageContainer>
  )
}

export default NotFound
